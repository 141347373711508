import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { navigate } from "@reach/router"
import SEO from "src/components/global/SEO.js"
import noHangers from "src/components/global/fn/noHangers.js"
import { Link } from "gatsby"
import Navigation from "src/components/global/Navigation.js"
import Footer from "src/components/global/Footer"
import typographySizes from "src/assets/styles/typographySizes.js"
import Cookies from "src/components/global/Cookies.js"
import mediaQuery from "src/assets/styles/mediaQuery"
import Wrapper from "src/components/global/Wrapper"
import BigText from "src/components/global/typography/BigText"
import MidText from "src/components/global/typography/MidText"
import Divider from "src/components/global/Divider.js"
// import LetsMeetSection from "src/components/global/LetsMeetSection"
import Slider from "src/components/global/Slider"
import ExternalBtn from "src/components/global/btn/ExternalBtn"

const TopWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Title = styled(BigText)`
  width: 50%;
  margin-right: 50%;

  ::first-letter {
    text-transform: uppercase;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
    /* margin-bottom: 50px; */
  }
`

const StyledDivider = styled(Divider)`
  margin-bottom: 40px;
  margin-top: 40px;
`

const BackLink = styled(Link)`
  font-size: ${typographySizes.s}px;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 20px;
  }
`

const ListTitle = styled(MidText)`
  margin-bottom: 20px;
`

const TopParagraph = styled(MidText)`
  width: 75%;

  .marginP {
    p {
      margin-bottom: 50px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    .marginP {
      p {
        margin-bottom: 20px;
      }
    }
  }
`

const OfferExpectSection = styled.div`
  width: 58.33%;
  margin-left: 25%;
  margin-bottom: 80px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 50px;
    &.last-of-type {
      margin-bottom: 0px;
    }
  }
  &.last-of-type {
    /* margin-bottom: 0px; */
  }
`

const OfferExpectList = styled.ul`
  margin-top: 20px;
  width: 100%;
`

const OfferExpectEl = styled.li`
  font-size: ${typographySizes.m}px;
  line-height: 1.5;
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  left: 25px;
  width: calc(100% - 25px);
  ::before {
    content: "→";
    position: absolute;
    left: -5px;
    transform: translateX(-100%);

    top: 1px;
  }
`
const BottomBtnWrapp = styled.div`
  width: 58.33%;
  margin-left: calc(25% - 52px);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
    margin-bottom: 50px;
  }
`
const goBack = () => {
  navigate(-1)
}
const RecruitmentLayout = ({ data }) => {
  const expect_array = data.strapiRecruitmentPositions.Expectations_List.split(
    ";"
  )
  const offer_array = data.strapiRecruitmentPositions.Offering_List.split(";")
  return (
    <>
      <Cookies />
      <SEO
        title={data.strapiRecruitmentPositions.Name}
        description={data.strapiRecruitmentPositions.Description}
      />
      <Navigation
        onlyPl
        navBtnLink={data.strapiGlobal.Nav_btn_link}
        navBtnText={data.strapiGlobal.Nav_btn_text}
      />
      <Wrapper>
        <TopWrapper>
          <Title>{noHangers(data.strapiRecruitmentPositions.Name)}</Title>
          <StyledDivider />
          <BackLink to={"/dolacz#positions"}>← powrót do listy</BackLink>
          <TopParagraph>
            <ReactMarkdown
              className={"marginP"}
              source={noHangers(data.strapiRecruitmentPositions.Description)}
            />
          </TopParagraph>
        </TopWrapper>
      </Wrapper>
      {/* <LetsMeetSection /> */}

      <Slider Gallery={data.strapiRecruitmentPositions.Img_Slider} />
      <Wrapper>
        <OfferExpectSection>
          <ListTitle>Oferujemy</ListTitle>
          <Divider />
          <OfferExpectList>
            {offer_array.map((item, index) => (
              <>
                <OfferExpectEl>{noHangers(item)}</OfferExpectEl>
              </>
            ))}
          </OfferExpectList>
        </OfferExpectSection>
        <OfferExpectSection className="last-of-type">
          <ListTitle>Oczekujemy</ListTitle>
          <Divider />
          <OfferExpectList>
            {expect_array.map((item, index) => (
              <>
                <OfferExpectEl>{item}</OfferExpectEl>
              </>
            ))}
          </OfferExpectList>
        </OfferExpectSection>
        <BottomBtnWrapp>
          <ExternalBtn
            link="https://forms.clickup.com/f/230p4-4019/7XZF1W0VB0UHWIY0VK"
            hoverText={data.strapiRecruitmentPositions.Cta.Hover}
            Mobile={data.strapiRecruitmentPositions.Cta.Mobile}
          >
            {data.strapiRecruitmentPositions.Cta.Main}
          </ExternalBtn>
        </BottomBtnWrapp>
      </Wrapper>
      <Footer companyData={data.strapiContactPageN} />
    </>
  )
}
export const query = graphql`
  query RecruitmentLayout($id: String!) {
    strapiGlobal {
      Nav_btn_link
      Nav_btn_text
    }
    strapiRecruitmentPositions(id: { eq: $id }) {
      Name
      id
      company {
        Title
      }
      Offering_List
      Expectations_List
      Description
      Img_Slider {
        caption
        height
        width
        localFile {
          childImageSharp {
            fluid(traceSVG: { background: "#ffffff", color: "#ffff00" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      Cta {
        Hover
        Main
        Mobile
      }
    }
    strapiContactPageN {
      Company_details_Address
      Company_details_Krs
      Company_details_Name
      Company_details_Nip
      Company_details_Regon
      Company_details_Title
    }
  }
`

export default RecruitmentLayout
